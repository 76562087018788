import { type Component, createSignal, onMount, Suspense } from "solid-js"
import { v4 as uuidv4 } from "uuid"
import { Card, CardContent } from "~/components/ui/card"
import { Project, bdb } from "~/lib/bdb"
import { Button } from "~/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui/dialog"
import { cache, createAsync, useNavigate } from "@solidjs/router"
import { getRequestEvent } from "solid-js/web"
import { getUserCredistsWithUnpaid } from "../service/credits"
import dayjs from "dayjs"

const getUser = cache(async () => {
  "use server"
  let event = getRequestEvent()
  return event?.locals.user
}, "userInfo")

const getUserCreditsTotal = cache(async () => {
  "use server"
  let event = getRequestEvent()
  if (event?.locals.user) {
    var { error, total } = await getUserCredistsWithUnpaid(event.locals.user.id)
    return { error, total }
  } else {
    return null
  }
}, "userCredits")

const Dashboard: Component = () => {
  let [projects, setProjects] = createSignal<Project[]>([])
  let user = createAsync(() => getUser())
  let credits = createAsync(() => getUserCreditsTotal())
  const navigate = useNavigate()

  onMount(() => {
    bdb.projects.toArray().then((projects) => {
      projects.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      setProjects(projects)
    })
  })

  return (
    <div class="mx-auto w-full max-w-6xl px-4 py-8 md:px-6">
      <div class="mb-6 flex items-center justify-between">
        <h1 class="text-2xl font-bold">项目列表</h1>
        <div>
          <Suspense>
            <span class="mr-2">{user()?.email || "未登录"}</span>
          </Suspense>
          <Suspense>
            <span class="mr-2">
              {credits()?.total ? credits()?.total + " 积分" : ""}
            </span>
          </Suspense>
          <Button
            onClick={async () => {
              let id = uuidv4()
              let now = new Date()
              let timeString = dayjs(now).format("YYYY-MM-DD HH:mm:ss")
              await bdb.projects.add({
                title: timeString,
                id: id,
                createdAt: now,
              })
              navigate("/projects/" + id)

              // bdb.projects.toArray().then((projects) => {
              //   setProjects(projects)
              // })
            }}
          >
            新建项目
          </Button>
        </div>
      </div>
      {projects().length == 0 ? (
        <div class="flex flex-col items-center justify-center space-y-4 rounded-lg bg-gray-100 py-20 dark:bg-gray-800">
          <InboxIcon class="h-12 w-12 text-gray-500 dark:text-gray-400" />
          <h2 class="text-lg font-medium text-gray-700 dark:text-gray-300">
            你还没有项目
          </h2>
          <p class="text-sm text-gray-500 dark:text-gray-400">
            点击新建按钮创建
          </p>
        </div>
      ) : null}

      <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
        {projects().map((project) => (
          <Card class="group relative">
            <div>
              {/* <img
                alt="Project Thumbnail"
                class="w-full h-48 object-cover rounded-t-lg"
                height={225}
                // src="/placeholder.svg"
                style={{
                  aspectRatio: '400/225',
                  objectFit: 'cover'
                }}
                width={400}
              /> */}
              <div class="absolute right-2 top-2 flex gap-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                <a href={`/projects/${project.id}`}>
                  <Button class="rounded-full" size="icon" variant="ghost">
                    <PencilIcon class="h-5 w-5" />
                    <span class="sr-only">编辑</span>
                  </Button>
                </a>
                <Dialog>
                  <DialogTrigger>
                    <Button class="rounded-full" size="icon" variant="ghost">
                      <TrashIcon class="h-5 w-5" />
                      <span class="sr-only">删除</span>
                    </Button>
                  </DialogTrigger>
                  <DialogContent class="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>删除 {project.title} ？</DialogTitle>
                    </DialogHeader>
                    <DialogFooter>
                      <Button
                        type="submit"
                        variant="destructive"
                        onClick={() => {
                          console.log("delete", project.id)
                          bdb.projects.delete(project.id)
                          bdb.projects.toArray().then((projects) => {
                            projects.sort(
                              (a, b) =>
                                b.createdAt.getTime() - a.createdAt.getTime(),
                            )
                            setProjects(projects)
                          })
                        }}
                      >
                        删除
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
            <CardContent class="p-4">
              <h3 class="mb-2 text-lg font-medium">{project.title}</h3>
              <p class="line-clamp-2 text-gray-500 dark:text-gray-400">
                {project.createdAt.toLocaleString()}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}

function InboxIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="22 12 16 12 14 15 10 15 8 12 2 12" />
      <path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
    </svg>
  )
}

function PlusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  )
}
function PencilIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
      <path d="m15 5 4 4" />
    </svg>
  )
}

function TrashIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 6h18" />
      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
    </svg>
  )
}
export default Dashboard
